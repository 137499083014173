import { useAuth } from "~/stores/auth";
import { createError } from "#app";

export const useApi = async (path, options = {}) => {
  const auth = useAuth();
  const runtimeConfig = useRuntimeConfig();

  const url = path.startsWith("https://")
    ? path
    : runtimeConfig.public.apiUrl + path;

  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + auth.token,
    Merchant: auth.merchantAccess.attributes.merchant_id,
    ...options.headers,
  };

  try {
    return await $fetch(url, {
      ...options,
      headers,
    });
  } catch (error) {
    useBugsnag().leaveBreadcrumb(error.data.error, error.data.data, "request");

    if (error.statusCode === 401) {
      auth.logout();

      // Throw an error to ensure no further processing occurs.
      throw createError({
        statusCode: 401,
        statusMessage: "Unauthorized: token expired, redirecting to login.",
      });
    }

    throw error;
  }
};
