
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91accountId_93MHIFOeXVb3Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/accounts/[id]/accounts/[accountId].vue?macro=true";
import { default as indexBthqr164GZMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/accounts/[id]/index.vue?macro=true";
import { default as _91id_93A4nQAse4qJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/accounts/[id].vue?macro=true";
import { default as indexVeN6fhEVDHMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/accounts/index.vue?macro=true";
import { default as _91id_93OFaOwSztLfMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/bundle-choices/[id].vue?macro=true";
import { default as indexcyyWCWbthgMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/bundle-choices/index.vue?macro=true";
import { default as _91id_93WYEXzewZcNMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/bundles/[id].vue?macro=true";
import { default as indexTWqkRG1mYaMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/bundles/index.vue?macro=true";
import { default as index7cYlhwYvyQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/index.vue?macro=true";
import { default as indexBkFZGQlC9FMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/index.vue?macro=true";
import { default as logwgAdmIXT7yMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersmvFcc9zq6QMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93f18sjgFSQUMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexMAGLswboqzMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93YoVw9OY8HbMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id].vue?macro=true";
import { default as card_45readers6bFL4QucqEMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id]/card-readers.vue?macro=true";
import { default as connected_45storesIiQUtbCYATMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id]/connected-stores.vue?macro=true";
import { default as deliveries8Bq2FQqbmuMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id]/deliveries.vue?macro=true";
import { default as webhookcoB5UbXaWyMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id]/webhook.vue?macro=true";
import { default as _91id_93EmmFb0OxogMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id].vue?macro=true";
import { default as _91driver_93fuLa4r8jiqMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/create/[driver].vue?macro=true";
import { default as indexFXvAlfgih8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/create/index.vue?macro=true";
import { default as index6KJhCs5sS5Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/index.vue?macro=true";
import { default as indexMCoclC4mtKMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/customer-sites/[id]/index.vue?macro=true";
import { default as payment_45methodsVCYUlf8iFnMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/customer-sites/[id]/payment-methods.vue?macro=true";
import { default as _91id_93W7GPndgnbhMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/customer-sites/[id].vue?macro=true";
import { default as indexnlWCZHg2qmMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/customer-sites/index.vue?macro=true";
import { default as deliveriesuYTLOD2UfZMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/deliveries.vue?macro=true";
import { default as _91id_93xOiehKwDdJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/discounts/discounts/[id].vue?macro=true";
import { default as indexLHV4vdTU8xMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/discounts/discounts/index.vue?macro=true";
import { default as indexYUX9H0lRo3Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/discounts/index.vue?macro=true";
import { default as _91id_939d436mFBYJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/employees/[id].vue?macro=true";
import { default as indexEMr7RiuF1tMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/employees/index.vue?macro=true";
import { default as _91id_93Uoa73VLhmgMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/gallery/[id].vue?macro=true";
import { default as index2eRMNQVqonMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/gallery/index.vue?macro=true";
import { default as indexrHeIKuAQToMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/index.vue?macro=true";
import { default as indexS2vRxKibuEMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/kitchens/index.vue?macro=true";
import { default as modifier_45groupscECj614PZBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/kitchens/modifier-groups.vue?macro=true";
import { default as modifiers2EEOgHssEsMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/kitchens/modifiers.vue?macro=true";
import { default as kitchensEyzfyy82OuMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/kitchens.vue?macro=true";
import { default as _91menuCategoryId_93UhCi4CZ1FHMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexJLO1gd42DcMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/menus/[id]/index.vue?macro=true";
import { default as indexubSkk05fK0Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/menus/index.vue?macro=true";
import { default as indexFwVVv2kWJJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/index.vue?macro=true";
import { default as indexjDSIOA6eNCMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93eLu58hevyOMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_9369F6z1t96fMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/modifier-groups/[id].vue?macro=true";
import { default as index1FyfZu5CaNMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/modifier-groups/index.vue?macro=true";
import { default as _91driver_93KPeDUhuZAGMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/oauth/[driver].vue?macro=true";
import { default as _91id_930yxQJ3PVIYMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/orders/[id].vue?macro=true";
import { default as indexHaXOIvbLmJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/orders/index.vue?macro=true";
import { default as indexajxbfRSww9Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/index.vue?macro=true";
import { default as _91id_930yQWRDJRloMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/product-categories/[id].vue?macro=true";
import { default as indexhkGYtD9GlAMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/product-categories/index.vue?macro=true";
import { default as _91id_93xoupXDmZXmMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/products/[id].vue?macro=true";
import { default as indexon2NmV4xaDMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/products/index.vue?macro=true";
import { default as profilecbD58x7qlfMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/profile.vue?macro=true";
import { default as cancellations5NavLNTwOiMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/cancellations.vue?macro=true";
import { default as cash_45transfersxB5IkePyQwMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/cash-transfers.vue?macro=true";
import { default as closing_45errors3YQj5md9LjMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/closing-errors.vue?macro=true";
import { default as delivery3Mgt5nBTiaMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/delivery.vue?macro=true";
import { default as downloadPaGUDTUwWGMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/download.vue?macro=true";
import { default as efficiencyX6PuBE1fmwMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/efficiency.vue?macro=true";
import { default as guestsOj1BMPMdLCMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/guests.vue?macro=true";
import { default as indexxXLU8McOguMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/index.vue?macro=true";
import { default as paymentsq5ep9d3tx1Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/payments.vue?macro=true";
import { default as productionfQhXY8eEiKMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/production.vue?macro=true";
import { default as reservationsik0UeiewhXMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/reservations.vue?macro=true";
import { default as reviewsNkh5XaYHwOMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/reviews.vue?macro=true";
import { default as tipsyiKtXzNeLgMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/tips.vue?macro=true";
import { default as accountsz44nv9Q6PkMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/accounts.vue?macro=true";
import { default as finances0BqQMFgPrDMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/finances.vue?macro=true";
import { default as indexC8Mog140rYMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/index.vue?macro=true";
import { default as integrationsBjWS0Vh0NJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/integrations.vue?macro=true";
import { default as operationsk7OibXM5yMMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/operations.vue?macro=true";
import { default as usersmEKFCzmbJnMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/users.vue?macro=true";
import { default as settingsjZ1Vr0D9kNMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings.vue?macro=true";
import { default as indexVBmoDdAamiMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stock/index.vue?macro=true";
import { default as modifier_45recipesaW0rDAueG4Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stock/modifier-recipes.vue?macro=true";
import { default as variant_45recipesJEvUjaAE98Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stock/variant-recipes.vue?macro=true";
import { default as stockK1PQha7ar2Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stock.vue?macro=true";
import { default as bookkeepingmPhL4agcqQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsC0TUrOoP9UMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexG88XjteYMcMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/index.vue?macro=true";
import { default as logyDN0ato6y8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/log.vue?macro=true";
import { default as orders0oBAfKgXhtMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93va7akmw4zQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexKO96tqEMmuMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93EaZ0R4fWEIMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id].vue?macro=true";
import { default as indexkIZTbtMeGgMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/index.vue?macro=true";
import { default as bookkeepinggedOL7OqtOMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/bookkeeping.vue?macro=true";
import { default as indexNIUw6t5OonMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/index.vue?macro=true";
import { default as kitchenmegWclxGIRMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/kitchen.vue?macro=true";
import { default as logrK4VLLF3eVMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/log.vue?macro=true";
import { default as stockjlV9iAhLJrMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/stock.vue?macro=true";
import { default as _91id_9368Jls1u57lMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id].vue?macro=true";
import { default as indexnXoMshE1AIMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/index.vue?macro=true";
import { default as indexAhA9owLsX3Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/colors/index.vue?macro=true";
import { default as _91id_93Xdq1z7ojDaMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/folders/[id].vue?macro=true";
import { default as index16lnNU0s7qMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/folders/index.vue?macro=true";
import { default as channel_45settlementsX0X4VH0zVaMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id]/channel-settlements.vue?macro=true";
import { default as indexufH3Nk8NK9Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id]/index.vue?macro=true";
import { default as logWnCFnATyD0Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id]/log.vue?macro=true";
import { default as navigationxMXF5jMtqtMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id]/navigation.vue?macro=true";
import { default as _91id_934Adt7lwz0EMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id].vue?macro=true";
import { default as indexwK2NmtKQavMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/index.vue?macro=true";
export default [
  {
    name: _91id_93A4nQAse4qJMeta?.name,
    path: "/accounts/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/accounts/[id].vue"),
    children: [
  {
    name: "accounts-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/accounts/[id]/accounts/[accountId].vue")
  },
  {
    name: "accounts-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/accounts/[id]/index.vue")
  }
]
  },
  {
    name: "accounts",
    path: "/accounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/accounts/index.vue")
  },
  {
    name: "bundles-bundle-choices-id",
    path: "/bundles/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/bundle-choices/[id].vue")
  },
  {
    name: "bundles-bundle-choices",
    path: "/bundles/bundle-choices",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/bundle-choices/index.vue")
  },
  {
    name: "bundles-bundles-id",
    path: "/bundles/bundles/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/bundles/[id].vue")
  },
  {
    name: "bundles-bundles",
    path: "/bundles/bundles",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/bundles/index.vue")
  },
  {
    name: "bundles",
    path: "/bundles",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/bundles/index.vue")
  },
  {
    name: _91id_93YoVw9OY8HbMeta?.name,
    path: "/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id].vue"),
    children: [
  {
    name: "channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/index.vue")
  },
  {
    name: "channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/log.vue")
  },
  {
    name: "channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/orders.vue")
  },
  {
    name: "channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "connections-id",
    path: "/connections/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id].vue"),
    children: [
  {
    name: "connections-id-card-readers",
    path: "card-readers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id]/card-readers.vue")
  },
  {
    name: "connections-id-connected-stores",
    path: "connected-stores",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id]/connected-stores.vue")
  },
  {
    name: "connections-id-deliveries",
    path: "deliveries",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id]/deliveries.vue")
  },
  {
    name: "connections-id-webhook",
    path: "webhook",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/[id]/webhook.vue")
  }
]
  },
  {
    name: "connections-create-driver",
    path: "/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/create/[driver].vue")
  },
  {
    name: "connections-create",
    path: "/connections/create",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/create/index.vue")
  },
  {
    name: "connections",
    path: "/connections",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/connections/index.vue")
  },
  {
    name: _91id_93W7GPndgnbhMeta?.name,
    path: "/customer-sites/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/customer-sites/[id].vue"),
    children: [
  {
    name: "customer-sites-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/customer-sites/[id]/index.vue")
  },
  {
    name: "customer-sites-id-payment-methods",
    path: "payment-methods",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/customer-sites/[id]/payment-methods.vue")
  }
]
  },
  {
    name: "customer-sites",
    path: "/customer-sites",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/customer-sites/index.vue")
  },
  {
    name: "deliveries",
    path: "/deliveries",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/deliveries.vue")
  },
  {
    name: "discounts-discounts-id",
    path: "/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/discounts/discounts/[id].vue")
  },
  {
    name: "discounts-discounts",
    path: "/discounts/discounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/discounts/discounts/index.vue")
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/discounts/index.vue")
  },
  {
    name: "employees-id",
    path: "/employees/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/employees/[id].vue")
  },
  {
    name: "employees",
    path: "/employees",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/employees/index.vue")
  },
  {
    name: "gallery-id",
    path: "/gallery/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/gallery/[id].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/gallery/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/index.vue")
  },
  {
    name: kitchensEyzfyy82OuMeta?.name,
    path: "/kitchens",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/kitchens.vue"),
    children: [
  {
    name: "kitchens",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/kitchens/index.vue")
  },
  {
    name: "kitchens-modifier-groups",
    path: "modifier-groups",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/kitchens/modifier-groups.vue")
  },
  {
    name: "kitchens-modifiers",
    path: "modifiers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/kitchens/modifiers.vue")
  }
]
  },
  {
    name: "menus-id-categories-menuCategoryId",
    path: "/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "menus-id",
    path: "/menus/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/menus/[id]/index.vue")
  },
  {
    name: "menus",
    path: "/menus",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/menus/index.vue")
  },
  {
    name: "modifiers",
    path: "/modifiers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/index.vue")
  },
  {
    name: _91id_9369F6z1t96fMeta?.name,
    path: "/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "modifiers-modifier-groups",
    path: "/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/modifiers/modifier-groups/index.vue")
  },
  {
    name: "oauth-driver",
    path: "/oauth/:driver()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/oauth/[driver].vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/orders/index.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/index.vue")
  },
  {
    name: "products-product-categories-id",
    path: "/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/product-categories/[id].vue")
  },
  {
    name: "products-product-categories",
    path: "/products/product-categories",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/product-categories/index.vue")
  },
  {
    name: "products-products-id",
    path: "/products/products/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/products/[id].vue")
  },
  {
    name: "products-products",
    path: "/products/products",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/products/products/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/profile.vue")
  },
  {
    name: "reports-cancellations",
    path: "/reports/cancellations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/cancellations.vue")
  },
  {
    name: "reports-cash-transfers",
    path: "/reports/cash-transfers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/cash-transfers.vue")
  },
  {
    name: "reports-closing-errors",
    path: "/reports/closing-errors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/closing-errors.vue")
  },
  {
    name: "reports-delivery",
    path: "/reports/delivery",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/delivery.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/efficiency.vue")
  },
  {
    name: "reports-guests",
    path: "/reports/guests",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/guests.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/index.vue")
  },
  {
    name: "reports-payments",
    path: "/reports/payments",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/payments.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/production.vue")
  },
  {
    name: "reports-reservations",
    path: "/reports/reservations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/reservations.vue")
  },
  {
    name: "reports-reviews",
    path: "/reports/reviews",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/reviews.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/reports/tips.vue")
  },
  {
    name: settingsjZ1Vr0D9kNMeta?.name,
    path: "/settings",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings.vue"),
    children: [
  {
    name: "settings-accounts",
    path: "accounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/accounts.vue")
  },
  {
    name: "settings-finances",
    path: "finances",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/finances.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/index.vue")
  },
  {
    name: "settings-integrations",
    path: "integrations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/integrations.vue")
  },
  {
    name: "settings-operations",
    path: "operations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/operations.vue")
  },
  {
    name: "settings-users",
    path: "users",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/settings/users.vue")
  }
]
  },
  {
    name: stockK1PQha7ar2Meta?.name,
    path: "/stock",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stock.vue"),
    children: [
  {
    name: "stock",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stock/index.vue")
  },
  {
    name: "stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stock/modifier-recipes.vue")
  },
  {
    name: "stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_93EaZ0R4fWEIMeta?.name,
    path: "/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id].vue"),
    children: [
  {
    name: "store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/index.vue")
  },
  {
    name: "store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/log.vue")
  },
  {
    name: "store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/orders.vue")
  },
  {
    name: "store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "store-settlements",
    path: "/store-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/store-settlements/index.vue")
  },
  {
    name: _91id_9368Jls1u57lMeta?.name,
    path: "/stores/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id].vue"),
    children: [
  {
    name: "stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/bookkeeping.vue")
  },
  {
    name: "stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/index.vue")
  },
  {
    name: "stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/kitchen.vue")
  },
  {
    name: "stores-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/log.vue")
  },
  {
    name: "stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "stores",
    path: "/stores",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/stores/index.vue")
  },
  {
    name: "terminals-colors",
    path: "/terminals/colors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/colors/index.vue")
  },
  {
    name: "terminals-folders-id",
    path: "/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/folders/[id].vue")
  },
  {
    name: "terminals-folders",
    path: "/terminals/folders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/folders/index.vue")
  },
  {
    name: _91id_934Adt7lwz0EMeta?.name,
    path: "/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id].vue"),
    children: [
  {
    name: "terminals-terminals-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id]/channel-settlements.vue")
  },
  {
    name: "terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id]/index.vue")
  },
  {
    name: "terminals-terminals-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id]/log.vue")
  },
  {
    name: "terminals-terminals-id-navigation",
    path: "navigation",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/[id]/navigation.vue")
  }
]
  },
  {
    name: "terminals-terminals",
    path: "/terminals/terminals",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250403123444/pages/terminals/terminals/index.vue")
  }
]